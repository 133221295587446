#portfolio{
    margin-top: 110px;
    padding: 10px;
}

.portfolioimg-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.project-headers-container{
    max-width: 340px;

    display: flex;
    margin-right: auto;
    margin-left: auto;
}
.project-headers{
    color:Black;
    
    color: rgb(134, 89, 4);
    font-weight: 1000;
    
}

.portfolio-img{
    max-width:100%;
    
}

.portfolio-text{
    line-height: 24px;
    font-size: 16px;
    text-align: left;
    
}

h4{
    font-size: 16px;
    
}

.portfolio-container{
    text-align: left;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    font-size: 16px;
    margin-top: 25px;
    
}
.github-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 14px;
    margin-bottom: 40px;
   
    
}

.github-link{
    display: flex;
    align-items: center;
    background-color:rgb(37, 179, 204);
    margin: 10px;
    padding: 8px;
    color: white;
    border-radius: 5px;
    
}

.space-portfolio{
    max-width: 80%;
    background-color: lightgray;
    height: 1px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

section#philosophy{
    margin-top: 150px;
    
    
}
@media (min-width:360px ){
    .github-link{
        display: flex;
        align-items: center;
        background-color:rgb(37, 179, 204);
        margin: 15px;
        padding: 10px;
        color: white;
        border-radius: 5px;
        
    }
    .github-container{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 40px;
        
        
    }
    .portfolio-img{
        max-width:340px;
        
    }
}


@media (min-width:400px ){
    
    .github-container{
       
        font-size: 16px;
    }
    .github-link{
        padding: 13px;
    }
        
}

@media (min-width:750px ){
    .portfolio-img{
        max-width:600px;
        }
        .portfolio-container{
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            line-height: 24px;
            font-size: 18px;
            }
        .github-container{
                text-align: center;
                font-size: 18px;
                }
        .project-headers-container{
                    max-width: 600px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
    
    
   