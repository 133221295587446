.contact-links-div{
    padding: 5px;
   overflow-wrap: break-word;
}
.contact-info{
    display: flex;
    flex-direction: column;
    margin: 75px 0px 50px 0px;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    
    
    

}
.contact-section{
    text-align: left;
    overflow-wrap: wrap;
    max-width: 100%;
}

@media (min-width:400px ){
    .contact-links-div{
        padding: 5px;
       
    }
    .contact-info{
        display: flex;
        flex-direction: column;    
        align-items: center;
        padding: 10px;  
        font-size: initial;
    
    }
    .contact-section{
        text-align: left;
        overflow-wrap: wrap;
        max-width: 100%;
    }
}
